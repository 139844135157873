const Locale = {
  item1: "Klasik",
  item2: "Penetrace",
  item3: "Barva světlá",
  item4: "Barva tmavá",
  item5: "Izolace",
  item6: "Fasádní barva akrylová",
  item7: "Fasádní silikát",
  item8: "Vícepráce",
  item9: "Sádrování/Akrylování",
  item10: "Doprava - Plzeň",
  item11: "Doprava - okolí",
  item12: "Olepování",
  item13: "Hrubý úklid",
  item14: "Omyvatelná barva",
  item15: "Easycare",
};

export default Locale;
