// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import '../CSS/Login.css'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/"); // Redirect to home after login
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="LoginContent">
      <div className="LoginMenu">
        <div className="LoginBackground">
          <p className="LoginMenuTitle">Přihlášení</p>
          <form onSubmit={handleLogin}>
            <input
              className="LoginInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              className="LoginInput"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Heslo"
              required
            />
            <button className="LoginButton" type="submit">Přihlásit se</button>
          </form>
        </div>
      </div>
      <h1 className="hidden-seo">Prodej kvalitního nábytku v Plzni - Sarte Nábytek</h1>
      <h2 className="hidden-seo">Stěhovací služby bez starostí v Plzni</h2>
      <h2 className="hidden-seo">Vyklízení a Přeprava nábytku v Plzni</h2>
      <h3 className="hidden-seo">Kvalitní služby pro domácnosti i kanceláře</h3>
      <h4 className="hidden-seo">Sarte - Nábytek, stěhování a vyklízení v Plzni</h4>
      <h5 className="hidden-seo">https://sarte-plzen.cz</h5>
    </div>
  );
};

export default Login;
