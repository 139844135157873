import React, { useState } from 'react';
import Backdrop from './Backdrop';
import '../CSS/Modal.css';

const Modal = ({ show, onClose, onSubmit, setName, setAdress, setPhone, setMailAdress }) => {
  return (
    <>
      <Backdrop show={show} onClick={onClose} />
      {show && (
        <div className="modal">
          <h1>Vytvořit zakázku</h1>
          <p>Zadejte požadované údaje pro vytvoření nové zakázky</p>
          <input
            className="CreateInput"
            placeholder="Název zakázky"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            className="CreateInput"
            placeholder="Adresa zakázky"
            onChange={(e) => setAdress(e.target.value)}
            required
          />
          <input
            className="CreateInput"
            placeholder="Telefonní číslo zákazníka"
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <input
            className="CreateInput"
            placeholder="E-mail zákazníka"
            onChange={(e) => setMailAdress(e.target.value)}
            required
          />
          <button className="normalButton" onClick={onSubmit}>Potvrdit</button>
        </div>
      )}
    </>
  );
};


export default Modal;