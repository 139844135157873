// src/components/Home.js
import React, {useEffect} from 'react'
import { useState } from 'react';
import { signOut } from "firebase/auth";
import { auth, database } from "../../firebase-config";
import { useNavigate } from "react-router-dom";
import Header from '../elements/Header';
import '../CSS/Home.css';
import icon_add from '../../plus.svg'
// import icon_logout from "../../logout.svg";
import Modal from '../elements/Modal';

import { getDatabase, ref, set, child, get } from "firebase/database";

const Home = () => {
  const navigate = useNavigate();
  const [ProjectName, setProjectName] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [MailAdress, setMailAdress] = useState('');
  const [ProjectAdress, setProjectAdress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('ERROR');
  const [modalDescription, setModalDescription] = useState('ERROR description');
  const [allProjects, setAllProjects] = useState([]);

  useEffect(() => {
    const loadProjects = async () => {
      const db = getDatabase();
      const allProjectsRef = ref(db, 'projects');
      const snapshot = await get(allProjectsRef);

      if (snapshot.exists()) {
        const projects = snapshot.val();
        // console.log(projects)
        // const lastFiveHistory = history.slice(-7).reverse();
        setAllProjects(projects);
        console.log(projects)
        // setIsHistory(true);
      }
    };

    loadProjects();
  }, []); // Empty dependency array ensures this runs once on component mount

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const updateAllProjects = async () => {
    const db = getDatabase();
    const allProjectsRef = ref(db, 'projects');
    const snapshot = await get(allProjectsRef);

    if (snapshot.exists()) {
      const projects = snapshot.val();
      // console.log(projects)
      // const lastFiveHistory = history.slice(-7).reverse();
      setAllProjects(projects);
      // console.log(projects)
      // setIsHistory(true);
    }
  }

  const addProject = async () => {
    const db = getDatabase();
    const allProjectsRef = ref(db, 'projects');
    try {
      // Step 1: Fetch the existing search history
      const snapshot = await get(allProjectsRef);
      let allProjects = [];

      if (snapshot.exists()) {
        // If search history exists, retrieve it
        allProjects = snapshot.val();
      }

      // Step 2: Create a new search entry
      const newProjectEntry = {
        name: ProjectName,
        adress: ProjectAdress,
        phone: PhoneNumber,
        mail: MailAdress,
        timestamp: new Date().toISOString(),
      };

      // Step 3: Add the new search entry to the search history array
      allProjects.push(newProjectEntry);

      // Step 4: Save the updated search history back to Firebase
      await set(allProjectsRef, allProjects);
      closeModal();
      updateAllProjects();

    } catch (error) {
      console.error("Error saving search history", error);
    }
    // try {
    //   const db = getDatabase();
    //   const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
    //   await set(ref(db, 'projects/' + ProjectId), {
    //     name: ProjectName,
    //     adress: ProjectAdress
    //   });
    //   console.log("creating project");
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const submitModal = () => {
    console.log("submit")
    const db = getDatabase();
    const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
    set(ref(db, 'projects/' + ProjectId), {
      name: ProjectName,
      adress: ProjectAdress
    });
    closeModal();
  }

  const openProject = (id) => {
    navigate("/project/" + id);
  }

  return (
    <div>
      <Modal show={showModal} onClose={closeModal} onSubmit={addProject} setName={setProjectName} setAdress={setProjectAdress} setPhone={setPhoneNumber} setMailAdress={setMailAdress} />
      <Header />
      <div className="HomeContent">
        <p className="HomeTitle">Vítej, Kristýna!</p>
        <div className="HomeSection">
          <div className="HomeSectionTitles">
            <p className="HomeSectionTitle">Přehled</p>
            <button className="HomeSectionAdd" onClick={openModal}>
              <img src={icon_add} className="AddIcon" alt="Add"/>
            </button>
            {/*<p className="HomeSectionTitle">Nastavení</p>*/}
          </div>
          <div className="HomeSectionContent">
            {/*{allProjects.map((item, index) => (*/}
            {/*  <div className="HomeSectionItem">*/}
            {/*    <p className="HomeSectionItemTitle">{item.name}</p>*/}
            {/*    <p className="HomeSectionItemText">{item.adress}</p>*/}
            {/*  </div>*/}
            {/*))}*/}
            {allProjects.length > 0 ? (
              // <div className="homeSectionContentAll">
                allProjects.map((item, index) => (
                  <div className="HomeSectionItem" onClick={() => openProject(index)}>
                    <p className="HomeSectionItemTitle">{item.name}</p>
                    <p className="HomeSectionItemText">{item.adress}</p>
                  </div>
                ))
            ) : (
              <p className="noProjectsText">Doposud nebyly vytvořeny žádné zakázky</p>
            )}
            {/*<div className="HomeSectionItem">*/}
            {/*  <p className="HomeSectionItemTitle">Název</p>*/}
            {/*  <p className="HomeSectionItemText">Topolová 145, Úherce 33023</p>*/}
            {/*</div>*/}
          </div>
        </div>
        {/*<button onClick={handleLogout}>Logout</button>*/}
      </div>
    </div>
  );
};

export default Home;
