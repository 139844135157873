import React, {useEffect, useState} from 'react'
import Backdrop from './Backdrop';
import '../CSS/Modal2.css';

const Modal4 = ({ show, onClose, onSubmit, deleteItemCount, deleteItemLabel, deleteItemPrice, deleteItemUnit }) => {
  return (
    <>
      <Backdrop show={show} onClick={onClose} />
      {show && (
        <div className="modal">
          <h1>Smazat {deleteItemLabel}</h1>
          <p className="deleteItemDescription">{deleteItemCount} {deleteItemUnit} - <span
            className="deleteItemPrice">{deleteItemPrice} Kč</span></p>
          {/*<p className="deleteItemPrice">{deleteItemPrice} Kč</p>*/}
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Název zakázky"*/}
          {/*  onChange={(e) => setName(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Adresa zakázky"*/}
          {/*  onChange={(e) => setAdress(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  type="number"*/}
          {/*  placeholder="Počet"*/}
          {/*  value={itemCount}*/}
          {/*  onChange={(e) => editItemCount(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          <button className="deleteItemConfirm" onClick={onSubmit}>Smazat</button>
          <button className="deleteItemCancel" onClick={onClose}>Zrušit</button>
        </div>
      )}
    </>
  );
};


export default Modal4;