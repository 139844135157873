import React, { useState } from 'react';
import Backdrop from './Backdrop';
import '../CSS/Modal2.css';

const Modal2 = ({ show, onClose, onSubmit, setItem, setItemCount, usedItems }) => {
  return (
    <>
      <Backdrop show={show} onClick={onClose} />
      {show && (
        <div className="modal">
          <h1>Přidat do zakázky</h1>
          <p>Zadejte požadované údaje pro přidání do zakázky</p>
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Název zakázky"*/}
          {/*  onChange={(e) => setName(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Adresa zakázky"*/}
          {/*  onChange={(e) => setAdress(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          <select id="projectItemAdd" className="ProjectItemAdd" onChange={(e) => setItem(e.target.value)}>
            <option value="hide" className="projectSelectHide">Vybrat</option>
            <option value="item1" className={`${usedItems["item1"] ? 'projectSelectHide' : ''}`}>Klasik</option>
            <option value="item2" className={`${usedItems["item2"] ? 'projectSelectHide' : ''}`}>Penetrace</option>
            <option value="item3" className={`${usedItems["item3"] ? 'projectSelectHide' : ''}`}>Barva světlá</option>
            <option value="item4" className={`${usedItems["item4"] ? 'projectSelectHide' : ''}`}>Barva tmavá</option>
            <option value="item5" className={`${usedItems["item5"] ? 'projectSelectHide' : ''}`}>Izolace</option>
            <option value="item6" className={`${usedItems["item6"] ? 'projectSelectHide' : ''}`}>Fasádní barva akrylová</option>
            <option value="item7" className={`${usedItems["item7"] ? 'projectSelectHide' : ''}`}>Fasádní silikát</option>
            <option value="item8" className={`${usedItems["item8"] ? 'projectSelectHide' : ''}`}>Vícepráce</option>
            <option value="item9" className={`${usedItems["item9"] ? 'projectSelectHide' : ''}`}>Sádrování/Akrylování</option>
            <option value="item10" className={`${usedItems["item10"] ? 'projectSelectHide' : ''}`}>Doprava - Plzeň</option>
            <option value="item11" className={`${usedItems["item11"] ? 'projectSelectHide' : ''}`}>Doprava - okolí</option>
            <option value="item12" className={`${usedItems["item12"] ? 'projectSelectHide' : ''}`}>Olepování</option>
            <option value="item13" className={`${usedItems["item13"] ? 'projectSelectHide' : ''}`}>Hrubý úklid</option>
            <option value="item14" className={`${usedItems["item14"] ? 'projectSelectHide' : ''}`}>Omyvatelná barva</option>
            <option value="item15" className={`${usedItems["item15"] ? 'projectSelectHide' : ''}`}>Easycare</option>
          </select>
          <input
            className="CreateInput"
            type="number"
            placeholder="Počet"
            onChange={(e) => setItemCount(e.target.value)}
            required
          />
          <button className="normalButton" onClick={onSubmit}>Potvrdit</button>
        </div>
      )}
    </>
  );
};


export default Modal2;