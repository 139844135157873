import React, {useEffect, useState} from 'react'
import Backdrop from './Backdrop';
import '../CSS/Modal2.css';

const Modal3 = ({ show, onClose, onSubmit, editItemCount, defaultEditItemCount, itemTitle }) => {
  const [itemCount, setItemCount] = useState(defaultEditItemCount);
  console.log(itemCount)
  useEffect(() => {
    setItemCount(defaultEditItemCount); // Update itemCount if editItemCount changes
  }, [defaultEditItemCount]);
  return (
    <>
      <Backdrop show={show} onClick={onClose} />
      {show && (
        <div className="modal">
          <h1>Upravit {itemTitle}</h1>
          <p>Upravte počet</p>
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Název zakázky"*/}
          {/*  onChange={(e) => setName(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          {/*<input*/}
          {/*  className="CreateInput"*/}
          {/*  placeholder="Adresa zakázky"*/}
          {/*  onChange={(e) => setAdress(e.target.value)}*/}
          {/*  required*/}
          {/*/>*/}
          <input
            className="CreateInput"
            type="number"
            placeholder="Počet"
            value={itemCount}
            onChange={(e) => editItemCount(e.target.value)}
            required
          />
          <button className="normalButton" onClick={onSubmit}>Uložit změny</button>
        </div>
      )}
    </>
  );
};


export default Modal3;