// src/components/Home.js
import React, {useEffect, useState} from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useNavigate, useLocation  } from "react-router-dom";
import '../CSS/Header.css';
import icon_logout from '../../logout.svg';
import {get, getDatabase, ref} from "firebase/database";

const Header = () => {
  const navigate = useNavigate();
  const [isActivePanel, setIsActivePanel] = useState(true);
  const [isActiveSettings, setIsActiveSettings] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      console.log(location.pathname)
      if (location.pathname === "/") {
        setIsActivePanel(true);
        setIsActiveSettings(false);
      } else if (location.pathname === "/settings") {
        setIsActivePanel(false);
        setIsActiveSettings(true);
      }
    };

    loadData();
  }, []); // Empty dependency array ensures this runs once on component mount

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const handlePanelClick = () => {
    setIsActivePanel(true);
    setIsActiveSettings(false);
    navigate("/");
    console.log(isActivePanel)
    console.log(isActiveSettings)
    console.log("-----------------------------------")
  }

  const handleSettingClick = () => {
    setIsActiveSettings(true);
    setIsActivePanel(false);
    // setIsActiveSettings(true);
    navigate("/settings");
    console.log(isActivePanel)
    console.log(isActiveSettings)
  }

  return (
    // <nav className="HeaderContent">
    //   <div className="HeaderMain">
    //     <p>header</p>
    //   </div>
    // </nav>
    // <nav className="nav">
    //   <a href="#" className="nav-item is-active" active-color="orange">Home</a>
    //   <a href="#" className="nav-item" active-color="green">About</a>
    //   <a href="#" className="nav-item" active-color="blue">Testimonials</a>
    //   <a href="#" className="nav-item" active-color="red">Blog</a>
    //   <a href="#" className="nav-item" active-color="rebeccapurple">Contact</a>
    //   <span className="nav-indicator"></span>
    // </nav>
    <div className="navigation-section">
      <nav className="nav">
        <a className={`nav-item ${isActivePanel ? 'is-active' : ''}`} onClick={handlePanelClick}>Panel</a>
        <a className={`nav-item ${isActiveSettings ? 'is-active' : ''}`} onClick={handleSettingClick}>Nastavení</a>
        {/*<a className="nav-item logout" onClick={handleLogout}>Odhlásit se</a>*/}
        {/*<span className="nav-indicator"></span>*/}
      </nav>
      <nav className="nav second">
        {/*<a className="nav-item logout" onClick={handleLogout}>Odhlásit se</a>*/}
        <img src={icon_logout} className="LogouIcon logout" alt="Logout" onClick={handleLogout}/>
      </nav>
    </div>
  )
    ;
};

export default Header;
