const IdNav = {
  item1: 0,
  item2: 1,
  item3: 2,
  item4: 3,
  item5: 4,
  item6: 5,
  item7: 6,
  item8: 7,
  item9: 8,
  item10: 9,
  item11: 10,
  item12: 11,
  item13: 12,
  item14: 13,
  item15: 14,
};

export default IdNav;
