// src/components/Home.js
import React, {useEffect} from 'react'
import { useState } from 'react';
import { signOut } from "firebase/auth";
import { auth, database } from "../../firebase-config";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../elements/Header';
import '../CSS/Project.css';
import icon_add from '../../plus.svg'
// import icon_logout from "../../logout.svg";
import Modal2 from '../elements/Modal2';
import Modal3 from '../elements/Modal3';
import Modal4 from '../elements/Modal4';
import Locale from '../elements/Locale';
import IdNav from '../elements/IdNav';

import { getDatabase, ref, set, child, get, remove } from "firebase/database";

const Project = () => {
  const navigate = useNavigate();
  const [ProjectName, setProjectName] = useState('');
  const [ProjectResult, setProjectResult] = useState(0);
  const [ProjectPrice, setProjectPrice] = useState(0);
  const [FinalPrice, setFinalPrice] = useState(0);
  const [Percentage, setPercentage] = useState(0);
  const [newPercentage, setNewPercentage] = useState(0);
  const [ProjectAdress, setProjectAdress] = useState('');
  const [addItemCount, setAddItemCount] = useState(0);
  const [editItemCount, setEditItemCount] = useState(0);
  const [itemTitle, setItemTitle] = useState('');
  const [addItem, setAddItem] = useState('');
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const { id } = useParams();
  const [editId, setEditId] = useState(0);
  const [deleteItemCount, setDeleteItemCount] = useState(0);
  const [deleteItemTitle, setDeleteItemTitle] = useState('');
  const [deleteItemPrice, setDeleteItemPrice] = useState(0);
  const [deleteItemUnit, setDeleteItemUnit] = useState('');
  const [itemsState, setItemsState] = useState({});
  const [deleteId, setDeleteId] = useState(0);
  // const [itemId, setItemId] = useState(0);
  // console.log(id)

  useEffect(() => {
    const loadProjectData = async () => {
      const db = getDatabase();
      const projectRef = ref(db, 'projects/' + id);
      const snapshot = await get(projectRef);

      if (snapshot.exists()) {
        const projectData = snapshot.val();
        // console.log(projects)
        // const lastFiveHistory = history.slice(-7).reverse();
        setProjectName(projectData.name);
        setProjectAdress(projectData.adress)
        if (projectData.items && Array.isArray(projectData.items)) {
          // Sum all prices in the items array
          const totalPrice = projectData.items.reduce((sum, item) => sum + (item.price || 0), 0);

          // Format the total price with a space as thousands separator
          const formattedPrice = totalPrice.toLocaleString('cs-CZ').replace(/\u00A0/g, ' ');


          // Log or set the total price as needed
          // console.log(totalPrice);  // This will log the total price
          if (projectData.percentage) {
            setPercentage(projectData.percentage);
            const onePercent = totalPrice/100
            const count = onePercent*projectData.percentage
            const newPrice = totalPrice + count
            const priceEdit = newPrice.toLocaleString('cs-CZ').replace(/\u00A0/g, ' ');
            // console.log(onePercent)
            setFinalPrice(priceEdit)
            setProjectPrice(totalPrice)
            // console.log(FinalPrice)
          } else {
            setProjectResult(formattedPrice);
            setFinalPrice(formattedPrice);
            setProjectPrice(totalPrice);
            // console.log(FinalPrice)
          }
          // setTotalPrice(totalPrice); // If you have a state for total price
        }
        // setIsHistory(true);
      }

      const projectDataRef = ref(db, 'projects/' + id + '/items');
      const ProjectDataSnapshot = await get(projectDataRef);

      if (ProjectDataSnapshot.exists()) {
        const data = ProjectDataSnapshot.val();
        // console.log(projects)
        // const lastFiveHistory = history.slice(-7).reverse();
        setProjectData(data);
        console.log(data)
        const itemsInUse = Object.values(data).map((item) => item.item); // Extract 'item' property
        console.log("Items in use:", itemsInUse);
        // Initialize state for each item as true if it’s in use
        const initialItemsState = itemsInUse.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {});
        setItemsState(initialItemsState);
        // setIsHistory(true);
      }

      // const itemsData = projectData.items;
      // const itemsInUse = Object.values(itemsData).map((item) => item.item); // Extract 'item' property
      //
      // // Now you have the list of items in use
      // console.log("Items in use:", itemsInUse);
      // return itemsInUse;

    };

    loadProjectData();
  }, []); // Empty dependency array ensures this runs once on component mount

  const updateProjectData = async () => {
    const db = getDatabase();
    const projectDataRef = ref(db, 'projects/' + id + '/items');
    const projectPercentageRef = ref(db, 'projects/' + id + '/percentage');
    const ProjectDataSnapshot = await get(projectDataRef);
    const ProjectPercentageSnapshot = await get(projectPercentageRef);

    if (ProjectDataSnapshot.exists()) {
      const data = ProjectDataSnapshot.val();
      // console.log(projects)
      // const lastFiveHistory = history.slice(-7).reverse();
      setProjectData(data);
      const totalPrice = data.reduce((sum, item) => sum + (item.price || 0), 0);
      const formattedPrice = totalPrice.toLocaleString('cs-CZ').replace(/\u00A0/g, ' ');

      setProjectResult(formattedPrice);
      console.log(data)
      const itemsInUse = Object.values(data).map((item) => item.item); // Extract 'item' property
      console.log("Items in use:", itemsInUse);
      // Initialize state for each item as true if it’s in use
      const initialItemsState = itemsInUse.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      setItemsState(initialItemsState);
      // setIsHistory(true);
    }
    if (ProjectPercentageSnapshot.exists()) {
      const percentageData = ProjectPercentageSnapshot.val();
      console.log(percentageData)
      if (percentageData) {
        setPercentage(percentageData);
        const onePercent = ProjectPrice/100
        const count = onePercent*percentageData
        const newPrice = ProjectPrice + count
        const priceEdit = newPrice.toLocaleString('cs-CZ').replace(/\u00A0/g, ' ');
        setFinalPrice(priceEdit)
      }
    }

  }

  // const addProject = async () => {
  //   const db = getDatabase();
  //   const allProjectsRef = ref(db, 'projects');
  //   try {
  //     // Step 1: Fetch the existing search history
  //     const snapshot = await get(allProjectsRef);
  //     let allProjects = [];
  //
  //     if (snapshot.exists()) {
  //       // If search history exists, retrieve it
  //       allProjects = snapshot.val();
  //     }
  //
  //     // Step 2: Create a new search entry
  //     const newProjectEntry = {
  //       name: ProjectName,
  //       adress: ProjectAdress,
  //       timestamp: new Date().toISOString(),
  //     };
  //
  //     // Step 3: Add the new search entry to the search history array
  //     allProjects.push(newProjectEntry);
  //
  //     // Step 4: Save the updated search history back to Firebase
  //     await set(allProjectsRef, allProjects);
  //     closeModal();
  //     window.location.reload(false);
  //
  //   } catch (error) {
  //     console.error("Error saving search history", error);
  //   }
  //   // try {
  //   //   const db = getDatabase();
  //   //   const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
  //   //   await set(ref(db, 'projects/' + ProjectId), {
  //   //     name: ProjectName,
  //   //     adress: ProjectAdress
  //   //   });
  //   //   console.log("creating project");
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // };

  const openModal2 = () => setShowModal2(true);
  const closeModal2 = () => setShowModal2(false);
  const submitModal2 = async () => {
    // console.log("submit")
    // const db = getDatabase();
    // const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
    // set(ref(db, 'projects/' + ProjectId), {
    //   name: ProjectName,
    //   adress: ProjectAdress
    // });
    // closeModal();
    console.log("submit")
    console.log(addItem)
    console.log(addItemCount)
    const db = getDatabase();
    const projectItemsRef = ref(db, 'projects/' + id + '/items');
    const settingsItemRef = ref(db, 'settings/' + IdNav[addItem]);
    try {
      // Step 1: Fetch the existing search history
      const snapshot = await get(projectItemsRef);
      let projectItems = [];

      if (snapshot.exists()) {
        // If search history exists, retrieve it
        projectItems = snapshot.val();
      }
      const settingsSnapshot = await get(settingsItemRef);
      console.log('---------------------------------------------------')
      console.log(settingsSnapshot.val().price*addItemCount)
      console.log('---------------------------------------------------')
      // Step 2: Create a new search entry
      const newProjectItemEntry = {
        item: addItem,
        count: addItemCount,
        price: settingsSnapshot.val().price*addItemCount,
        unit: settingsSnapshot.val().unit,
      };

      // Step 3: Add the new search entry to the search history array
      projectItems.push(newProjectItemEntry);

      // Step 4: Save the updated search history back to Firebase
      await set(projectItemsRef, projectItems);
      closeModal2();
      updateProjectData();

    } catch (error) {
      console.error("Error saving item", error);
    }
  }

  const openModal3 = () => setShowModal3(true);
  const closeModal3 = () => setShowModal3(false);
  const submitModal3 = async () => {
    // console.log("submit")
    // const db = getDatabase();
    // const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
    // set(ref(db, 'projects/' + ProjectId), {
    //   name: ProjectName,
    //   adress: ProjectAdress
    // });
    // closeModal();
    console.log("edit")
    // console.log(addItem)
    // console.log(addItemCount)
    const db = getDatabase();
    console.log(editId)
    console.log(editItemCount)
    const editItemRef = ref(db, 'projects/' + id + '/items/' + editId);
    // const settingsItemRef = ref(db, 'settings/' + addItem);
    try {
      // // Step 1: Fetch the existing search history
      const snapshot = await get(editItemRef);
      let editItem = [];
      //
      if (snapshot.exists()) {
        // If search history exists, retrieve it
        editItem = snapshot.val();
        console.log(editItem)
      }
      const settingsItemRef = ref(db, 'settings/' + IdNav[editItem.item]);
      const settingsSnapshot = await get(settingsItemRef);
      // Step 2: Create a new search entry
      const edittedItemEntry = {
        count: editItemCount,
        item: editItem.item,
        price: settingsSnapshot.val().price*editItemCount,
        unit: editItem.unit,
      };
      //
      // // Step 3: Add the new search entry to the search history array
      // editItem.push(edittedItemEntry);

      // Step 4: Save the updated search history back to Firebase
      await set(editItemRef, edittedItemEntry);
      closeModal3();
      updateProjectData();

    } catch (error) {
      console.error("Error saving item", error);
    }
  }

  const editItem = (index, count, label) => {
    setEditItemCount(count);
    setItemTitle(label);
    setEditId(index);
    openModal3();
  }

  const openModal4 = () => setShowModal4(true);
  const closeModal4 = () => setShowModal4(false);
  // const submitModal4 = async () => {
  //   // console.log("submit")
  //   // const db = getDatabase();
  //   // const ProjectId = ProjectName.toLowerCase().replace(/\s+/g, '_');
  //   // set(ref(db, 'projects/' + ProjectId), {
  //   //   name: ProjectName,
  //   //   adress: ProjectAdress
  //   // });
  //   // closeModal();
  //   console.log("edit")
  //   // console.log(addItem)
  //   // console.log(addItemCount)
  //   const db = getDatabase();
  //   console.log(deleteId)
  //   console.log(deleteItemCount)
  //   const deleteItemRef = ref(db, 'projects/' + id + '/items/' + deleteId);
  //   // const settingsItemRef = ref(db, 'settings/' + addItem);
  //   try {
  //     await remove(deleteItemRef);
  //     closeModal4();
  //     updateProjectData();
  //   } catch (error) {
  //     console.error("Error deleting item", error);
  //   }
  // }
  const submitModal4 = async () => {
    const db = getDatabase();
    const projectId = id;  // Assuming `id` is your project ID
    const deleteItemRef = ref(db, `projects/${projectId}/items/${deleteId}`);

    try {
      // Remove the item at deleteId
      await remove(deleteItemRef);

      // Fetch updated data to check if there are any items left
      const snapshot = await get(ref(db, `projects/${projectId}/items`));
      if (snapshot.exists()) {
        const items = snapshot.val();
        const reindexedItems = Object.values(items); // Re-create an array with consecutive indices

        // Check if there are any items left after deletion
        if (reindexedItems.length === 0) {
          // If no items left, set the items to an empty array or remove the items key
          await set(ref(db, `projects/${projectId}/items`), []);
        } else {
          // Save reindexed items back to Firebase
          await set(ref(db, `projects/${projectId}/items`), reindexedItems);
        }
      } else {
        // If no snapshot exists, it means items key is already empty, so reset it
        await set(ref(db, `projects/${projectId}/items`), []);
      }

      closeModal4();
      updateProjectData(); // Refresh the UI with updated data
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };


  const deleteItem = async (index, count, label, price, unit) => {
    console.log("-------------------------------------")
    console.log(index)
    console.log(count)
    console.log(label)
    console.log(price)
    console.log(unit)
    console.log("-------------------------------------")
    setDeleteItemCount(count);
    setDeleteItemTitle(label);
    setDeleteItemPrice(price);
    setDeleteItemUnit(unit);
    setDeleteId(index);
    console.log(deleteItemCount)
    console.log(deleteId)
    openModal4();
  }

  const updatePercentage = async (percents) => {
    setNewPercentage(percents)
  }

  const savePercentage = async () => {
    const db = getDatabase();
    const percentageRef = ref(db, 'projects/' + id + '/percentage');
    try {
      await set(percentageRef, newPercentage);
      console.log("saving percentage");
      updateProjectData(); // Refresh the UI with updated data
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Modal4 show={showModal4} onClose={closeModal4} onSubmit={submitModal4} deleteItemCount={deleteItemCount} deleteItemLabel={deleteItemTitle} deleteItemPrice={deleteItemPrice} deleteItemUnit={deleteItemUnit}/>
      <Modal3 show={showModal3} onClose={closeModal3} onSubmit={submitModal3} editItemCount={setEditItemCount} defaultEditItemCount={editItemCount} itemTitle={itemTitle}/>
      <Modal2 show={showModal2} onClose={closeModal2} onSubmit={submitModal2} setItem={setAddItem} setItemCount={setAddItemCount} usedItems={itemsState}/>
      <Header />
      <div className="ProjectContent">
        <p className="ProjectTitle">Úprava zakázky</p>
        <div className="ProjectPercentageTable">
          <p className="ProjectPercentageTableTitle">Přidat procenta</p>
          <div className="ProjectPercentageTableContent">
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(1)}>+1%</button>
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(5)}>+5%</button>
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(10)}>+10%</button>
            <input className="ProjectPercentageTableInput" type="number" placeholder="Vlastní"             onChange={(e) => setNewPercentage(e.target.value)}/>
          </div>
          <button className="ProjectPercentageTableButtonConfirm" onClick={savePercentage}>Uložit</button>
        </div>
        <div className="ProjectResultTableMobile">
          <button className="ProjectSectionResultMobile">{Percentage}%</button>
          <button className="ProjectSectionResultMobile">Výsledná částka: {FinalPrice} Kč</button>
        </div>
        <div className="ProjectPercentageTable2">
          <p className="ProjectPercentageTableTitle">Přidat procenta</p>
          <div className="ProjectPercentageTableContent">
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(1)}>+1%</button>
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(5)}>+5%</button>
            <button className="ProjectPercentageTableButton" onClick={() => updatePercentage(10)}>+10%</button>
            <input className="ProjectPercentageTableInput" type="number" placeholder="Vlastní"
                   onChange={(e) => setNewPercentage(e.target.value)}/>
          </div>
          <button className="ProjectPercentageTableButtonConfirm" onClick={savePercentage}>Uložit</button>
        </div>
        <div className="ProjectSection">
        <div className="ProjectSectionTitles">
            <div className="ProjectSectionTitlesBox">
              <p className="ProjectSectionTitle">{ProjectName}</p>
              <p className="ProjectSectionDescription">{ProjectAdress}</p>
            </div>
            <div className="HomeSectionTitlesRight">
              <button className="ProjectSectionResult">{Percentage}%</button>
              <button className="ProjectSectionResult">Výsledná částka: {FinalPrice} Kč</button>
              <button className="ProjectSectionAdd" onClick={openModal2}>
                <img src={icon_add} className="AddIcon" alt="Add"/>
              </button>
            </div>
            {/*<p className="HomeSectionTitle">{ProjectName}</p>*/}
            {/*<button className="ProjectSectionAdd" onClick={openModal2}>*/}
            {/*  <img src={icon_add} className="AddIcon" alt="Add"/>*/}
            {/*</button>*/}
            {/*<p className="HomeSectionTitle">Nastavení</p>*/}
          </div>
          <div className="ProjectSectionContent">
            {/*{allProjects.map((item, index) => (*/}
            {/*  <div className="HomeSectionItem">*/}
            {/*    <p className="HomeSectionItemTitle">{item.name}</p>*/}
            {/*    <p className="HomeSectionItemText">{item.adress}</p>*/}
            {/*  </div>*/}
            {/*))}*/}
            {projectData.length > 0 ? (
              // <div className="projectSectionContentAll">
              Object.values(projectData).map((item, index) => (
                <div className="ProjectSectionItem">
                  <div className="ProjectSectionItemTitles">
                    <p className="ProjectSectionItemTitle">{Locale[item.item] || item.item}</p>
                    <p className="ProjectSectionItemCount">{item.count} {item.unit}</p>
                  </div>
                  {/*<p className="ProjectSectionItemTitle">{Locale[item.item] || item.item}</p>*/}
                  <p className="ProjectSectionItemPrice">{item.price} Kč</p>
                  <button className="ProjectSectionItemEdit"
                          onClick={() => editItem(index, item.count, Locale[item.item] || item.item)}>Upravit
                  </button>
                  <button className="ProjectSectionItemDelete"
                          onClick={() => deleteItem(index, item.count, Locale[item.item] || item.item, item.price, item.unit)}>Smazat
                  </button>
                </div>
              ))
            ) : (
              <p className="noProjectDataText">Doposud nebyly přidány žádné věci k této zakázce</p>
            )}
            {/*<div className="HomeSectionItem">*/}
            {/*  <p className="HomeSectionItemTitle">Název</p>*/}
            {/*  <p className="HomeSectionItemText">Topolová 145, Úherce 33023</p>*/}
            {/*</div>*/}
          </div>
        </div>
        {/*<button onClick={handleLogout}>Logout</button>*/}
      </div>
    </div>
  );
};

export default Project;
