// src/components/Home.js
import React, {useEffect} from 'react'
import { useState } from 'react';
import { signOut } from "firebase/auth";
import { auth, database } from "../../firebase-config";
import { useNavigate } from "react-router-dom";
import Header from '../elements/Header';
import '../CSS/Settings.css';
import IdNav from '../elements/IdNav';

import { getDatabase, ref, set, child, get } from "firebase/database";

const Settings = () => {
  const navigate = useNavigate();
  const [ProjectName, setProjectName] = useState('');
  const [ProjectAdress, setProjectAdress] = useState('');
  // const [showModal, setShowModal] = useState(false);
  // const [modalTitle, setModalTitle] = useState('ERROR');
  // const [modalDescription, setModalDescription] = useState('ERROR description');
  // const [allProjects, setAllProjects] = useState([]);
  const [settings1, setSettings1] = useState(0);
  const [settings2, setSettings2] = useState(0);
  const [settings3, setSettings3] = useState(0);
  const [settings4, setSettings4] = useState(0);
  const [settings5, setSettings5] = useState(0);
  const [settings6, setSettings6] = useState(0);
  const [settings7, setSettings7] = useState(0);
  const [settings8, setSettings8] = useState(0);
  const [settings9, setSettings9] = useState(0);
  const [settings10, setSettings10] = useState(0);
  const [settings11, setSettings11] = useState(0);
  const [settings12, setSettings12] = useState(0);
  const [settings13, setSettings13] = useState(0);
  const [settings14, setSettings14] = useState(0);
  const [settings15, setSettings15] = useState(0);

  useEffect(() => {
    const loadSettings = async () => {
      const db = getDatabase();
      const allSettingsRef = ref(db, 'settings');
      const snapshot = await get(allSettingsRef);

      if (snapshot.exists()) {
        const settings = snapshot.val();
        console.log(settings)
        const id1 = IdNav["item1"]
        const id2 = IdNav["item2"]
        const id3 = IdNav["item3"]
        const id4 = IdNav["item4"]
        const id5 = IdNav["item5"]
        const id6 = IdNav["item6"]
        const id7 = IdNav["item7"]
        const id8 = IdNav["item8"]
        const id9 = IdNav["item9"]
        const id10 = IdNav["item10"]
        const id11 = IdNav["item11"]
        const id12 = IdNav["item12"]
        const id13 = IdNav["item13"]
        const id14 = IdNav["item14"]
        const id15 = IdNav["item15"]
        // console.log(settings[id3].price)
        setSettings1(settings[id1].price)
        setSettings2(settings[id2].price)
        setSettings3(settings[id3].price)
        setSettings4(settings[id4].price)
        setSettings5(settings[id5].price)
        setSettings6(settings[id6].price)
        setSettings7(settings[id7].price)
        setSettings8(settings[id8].price)
        setSettings9(settings[id9].price)
        setSettings10(settings[id10].price)
        setSettings11(settings[id11].price)
        setSettings12(settings[id12].price)
        setSettings13(settings[id13].price)
        setSettings14(settings[id14].price)
        setSettings15(settings[id15].price)
      }
    };

    loadSettings();
  }, []); // Empty dependency array ensures this runs once on component mount

  const saveSettings = async () => {
    const settingsArray = [
      { item: "item1", price: settings1, unit: "m²" },
      { item: "item2", price: settings2, unit: "m²" },
      { item: "item3", price: settings3, unit: "m²" },
      { item: "item4", price: settings4, unit: "m²" },
      { item: "item5", price: settings5, unit: "m²" },
      { item: "item6", price: settings6, unit: "m²" },
      { item: "item7", price: settings7, unit: "m²" },
      { item: "item8", price: settings8, unit: "m²" },
      { item: "item9", price: settings9, unit: "m²" },
      { item: "item10", price: settings10, unit: "m²" },
      { item: "item11", price: settings11, unit: "m²" },
      { item: "item12", price: settings12, unit: "m²" },
      { item: "item13", price: settings13, unit: "m²" },
      { item: "item14", price: settings14, unit: "m²" },
      { item: "item15", price: settings15, unit: "m²" },
    ];
    const db = getDatabase();

    try {
      await set(ref(db, 'settings'), settingsArray);
      console.log("Settings saved successfully");
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="SettingsContent">
        <div className="settingsTitlesSection">
          <p className="SettingsTitle">Nastavení</p>
          <button className="SettingsSaveButton" onClick={saveSettings}>Uložit</button>
        </div>
        {/*<p className="SettingsTitle">Nastavení</p>*/}
        <div className="SettingsSection">
          <div className="SettingsSectionContent">
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Klasik | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings1}
                  onChange={(e) => setSettings1(e.target.value)}
                  placeholder="Item 1 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Penetrace | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings2}
                  onChange={(e) => setSettings2(e.target.value)}
                  placeholder="Item 2 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Barva světlá | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings3}
                  onChange={(e) => setSettings3(e.target.value)}
                  placeholder="Item 3 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Barva tmavá | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings4}
                  onChange={(e) => setSettings4(e.target.value)}
                  placeholder="Item 4 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Izolace | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings5}
                  onChange={(e) => setSettings5(e.target.value)}
                  placeholder="Item 5 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Fasádní barva akrylová | <span
                className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings6}
                  onChange={(e) => setSettings6(e.target.value)}
                  placeholder="Item 6 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Fasádní silikát | <span
                className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings7}
                  onChange={(e) => setSettings7(e.target.value)}
                  placeholder="Item 7 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Vícepráce | <span className="SettingsSectionItemDescription">Cena za hodinu</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings8}
                  onChange={(e) => setSettings8(e.target.value)}
                  placeholder="Item 8 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Sádrování/Akrylování | <span
                className="SettingsSectionItemDescription">Cena za hodinu</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings9}
                  onChange={(e) => setSettings9(e.target.value)}
                  placeholder="Item 9 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Doprava - Plzeň | <span
                className="SettingsSectionItemDescription">Cena za cestu</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings10}
                  onChange={(e) => setSettings10(e.target.value)}
                  placeholder="Item 10 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Doprava - okolí | <span className="SettingsSectionItemDescription">Cena za cestu</span></p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings11}
                  onChange={(e) => setSettings11(e.target.value)}
                  placeholder="Item 11 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Olepování | <span className="SettingsSectionItemDescription">Cena za hodinu</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings12}
                  onChange={(e) => setSettings12(e.target.value)}
                  placeholder="Item 12 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Hrubý úklid | <span className="SettingsSectionItemDescription">Cena za hodinu</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings13}
                  onChange={(e) => setSettings13(e.target.value)}
                  placeholder="Item 13 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Omyvatelná barva | <span
                className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings14}
                  onChange={(e) => setSettings14(e.target.value)}
                  placeholder="Item 14 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
            <div className="SettingsSectionItem">
              <p className="SettingsSectionItemTitle">Easycare | <span className="SettingsSectionItemDescription">Cena za m²</span>
              </p>
              {/*<p className="SettingsSectionItemText">ss</p>*/}
              <div className="SettingsSectionItemInputSection">
                <input
                  className="SettingsInput"
                  type="number"
                  value={settings15}
                  onChange={(e) => setSettings15(e.target.value)}
                  placeholder="Item 15 price"
                  required
                />
                <p className="SettingsSectionItemText">Kč</p>
              </div>
            </div>
          </div>
        </div>
        {/*<button onClick={handleLogout}>Logout</button>*/}
      </div>
    </div>
  );
};

export default Settings;
