// src/components/Register.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import '../CSS/Register.css'

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/"); // Redirect to home after registration
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="RegisterContent">
      <div className="RegisterMenu">
        <div className="RegisterBackground">
          <p className="RegisterMenuTitle">Registrace</p>
          <form onSubmit={handleRegister}>
            <input
              className="RegisterInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              className="RegisterInput"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Heslo"
              required
            />
            <button className="RegisterButton" type="submit">Zaregistrovat se</button>
          </form>
        </div>
      </div>
    </div>
)
  ;
};

export default Register;
